.more {
    color: darkblue;
    font-weight: bold;
}

.more:hover {
    cursor: pointer;
}

.job-details {
    padding: 10px;
}