.react-tagsinput {
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    padding-left: 5px;
    padding-top: 5px;
    width: 600px;
}

.react-tagsinput--focused {
    border-color: rgb(25,34,60);
}

.react-tagsinput-tag {
    background-color: rgb(35,44,70);;
    border-radius: 2px;
    border: 1px solid rgb(25,34,60);
    color: #638421;
    display: inline-block;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px;
  }

